/*
 * Metrics styles
 * we have applied in metrics.component.html the class "metrics-page" to ensure that these styles are applied to the metrics page only.
 * This is to avoid conflicts with other styles that may be applied to the app, since we migrated Metrics from the monorepo to the frontend repository.
 * It might be the case that part of this file can simplified, but since we are expecting a designer in the next months, it is probably wise to keep this 
 * separate from the rest of the styles, and address changes when time comes.
 */

.metrics-page {

  // Base element styles
  hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
  }

  ul,
  menu {
    list-style: none;
    margin: 0;
    padding: 0
  }

  .border-alt-gray {
    --tw-border-opacity: 1;
    border-color: rgb(229 226 233 / var(--tw-border-opacity));
  }

  .border-pink-dark {
    --tw-border-opacity: 1;
    border-color: rgb(237 60 106 / var(--tw-border-opacity));
  }

  .bg-pink-dark {
    --tw-bg-opacity: 1;
    background-color: rgb(237 60 106 / var(--tw-bg-opacity));
  }

  .bg-green-dark {
    --tw-bg-opacity: 1;
    background-color: rgb(71 198 147 / var(--tw-bg-opacity));
  }

  // Text utilities
  .text-gray-dark {
    --tw-text-opacity: 1;
    color: rgb(23 20 28 / var(--tw-text-opacity));
  }

  .text-green-dark {
    --tw-text-opacity: 1;
    color: rgb(71 198 147 / var(--tw-text-opacity));
  }

  // Custom components
  .currencies-mobile-select {
    @media (min-width: 768px) {
      display: none
    }
  }

  .currencies-desktop-select {
    display: none;

    @media (min-width: 768px) {
      display: block
    }
  }

  .clickable {
    cursor: pointer
  }

  // Material styles
  .mat-form-field-no-errors .mat-form-field-wrapper {
    margin-bottom: -1.25em !important;
  }

  .mat-form-field-bg-white.mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: #fff;
    border-radius: 5px;
  }

  // Paragraph styles
  p,
  textarea {
    line-height: 28px !important;
  }

  p {
    margin: 0 !important;
    padding: 0 !important;
  }

  .mat-typography p {
    font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 12px;
  }

  // Navigation tabs
  .text-md {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .font-light {
    font-weight: 300;
  }

  // Metrics widgets
  .metrics-widget {
    padding: 0.5rem 1.5rem;
    container-type: inline-size;

    .text-gray {
      color: #6B7280;
    }

    .text-xl {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    .md\:text-2xl {
      @media (min-width: 768px) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    .font-bold {
      font-weight: 700;
    }
  }

  // Data table styles
  .mat-table {
    width: 100%;
    background: transparent;

    .mat-header-cell {
      color: #1F2937;
      border-bottom-color: #0000001f;
    }

    .mat-cell {
      color: #1F2937;
      border-bottom-color: #0000001f;
    }
  }

  // Filter components
  .filter__option-btn {
    background-color: #F3F4F6;
    font-family: 'Cera', 'Helvetica Neue', sans-serif;
    color: #1F2937;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    border: 1px solid transparent;

    &:hover {
      background-color: #E5E7EB;
    }

    &.btn__no-value {
      background: #FEF3C7;
    }
  }

  .filter__icon {
    margin-right: 0.5rem;
  }

  // Button styles
  .button__small {
    height: 2rem;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .button__text {
    background: transparent;
    color: #374151;

    &:hover {
      background-color: #F3F4F6;
    }
  }

  .paginator {
    .mat-button-toggle {
      &.page__active {
        background-color: #47c693;
        color: white;
      }
    }
  }

  // Material form field styles
  .mat-form-field-flex {
    padding: 0 16px !important;
    height: 48px;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: #0000001f;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #000000de;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: #47c693 !important;
  }

  .mat-form-field-label {
    color: #b3afbb;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
    color: #47c693;
  }

  .mat-input-element {
    color: #17141c !important;
  }

  .mat-form-field.ng-invalid.ng-touched .mat-form-field-underline {
    background-color: #ffc74c;
  }

  .mat-form-field.ng-invalid.ng-touched .mat-form-field-label {
    color: #5c576a;
  }

  .mat-error {
    color: #ffc74c !important;
  }

  .mat-form-field.ng-invalid.ng-touched .mat-form-field-outline {
    background: rgba(255, 242, 198, .5);
    border-radius: 4px;
  }

  .mat-form-field.ng-invalid.ng-touched .mat-input-element {
    color: #000 !important;
  }

  .mat-form-field.ng-invalid.ng-touched .mat-form-field-outline-start {
    border: 2px solid #ffc74c !important;
    border-right-style: hidden !important;
  }

  .mat-form-field.ng-invalid.ng-touched .mat-form-field-outline-gap {
    border: 2px solid #ffc74c !important;
    border-right-style: hidden !important;
    border-left-style: hidden !important;
  }

  .mat-form-field.ng-invalid.ng-touched .mat-form-field-outline-end {
    border: 2px solid #ffc74c !important;
    border-left-style: hidden !important;
  }

  .mat-form-field.ng-invalid.ng-touched.mat-focused .mat-form-field-outline-gap {
    border-top-style: hidden !important;
  }

  .mat-form-field-disabled .mat-form-field-outline {
    background: #f8f7f9;
    border-radius: 5px;
  }

  .mat-form-field-prefix {
    left: -6px;
  }

  .mat-form-field-infix {
    padding: 5px 0 0 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-label {
    top: 22px !important;
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2em) scale(.75);
  }

  .mat-form-field-subscript-wrapper {
    padding: 0 !important;
  }

  // Mini input styles
  .input__mini {
    font-size: 14px;

    input,
    .mat-select-value {
      height: 17px;
    }

    mat-icon {
      font-size: 16px !important;
      position: relative;
    }

    .mat-form-field-flex {
      height: 32px;
      margin-top: -.5em !important;
    }

    .mat-form-field-outline {
      height: 32px;
    }

    .mat-form-field-infix {
      padding: 0 !important;
    }

    .mat-form-field-label {
      top: 15px !important;
    }

    &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-.8em) scale(.75);
    }
  }

  // Color modifiers - these should be at the very end to ensure they take precedence
  .raspberry-darker {
    &.button__text {
      color: #ed3c6a;

      &:hover {
        background-color: #FDF2F8;
      }
    }
  }

  .grass-darker {
    &.button__text {
      color: #47c693;

      &:hover {
        background-color: #ebf9f3;
      }
    }
  }

  // Other color modifiers that don't need button context
  .text-green-dark {
    color: #47c693 !important;
  }

  .text-pink-dark {
    color: #ED3C6A !important;
  }

  // Material Tab styles
  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, .12)
  }

  .mat-tab-group-inverted-header .mat-tab-header {
    border-top: 1px solid rgba(0, 0, 0, .12);
    border-bottom: none
  }

  .mat-tab-group[class*=mat-background-]>.mat-tab-header,
  .mat-tab-nav-bar[class*=mat-background-] {
    border-bottom: none;
    border-top: none
  }

  // Additional Material styles
  .mat-select-arrow {
    border-left: 0 !important;
    border-right: 0 !important;
    position: relative;
    display: block;
    top: -5px;
    left: -5px;
    height: 12px;

    &:before,
    &:after {
      position: absolute;
      display: block;
      content: "";
      border: 6px solid transparent;
    }

    &:before {
      top: 0;
      border-top-color: #5c576a;
    }

    &:after {
      top: -3px;
      border-top-color: #fff;
    }
  }

  .mat-tab-label {
    padding: 0 !important;
    min-width: 120px !important;
    font-size: 16px !important;

    &.mat-tab-label-active {
      opacity: 1 !important;

      .mat-tab-label-content {
        color: #5c576a !important;
      }
    }
  }

  .mat-ink-bar {
    background-color: #ed3c6a !important;
  }

  .mat-checkbox {
    padding: 8px 8px 12px;
    border-radius: 4px;

    &:hover {
      background: #f8f7f9;
    }

    &.mat-checkbox-disabled:hover {
      background: none;
    }
  }
}